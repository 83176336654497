:root {
    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 95%;

    --color-white: #fff;
    --color-primary: #4db5ff;
}

.banner {
    margin-top: 0;
    padding: 190px 0 100px 0;
    /* background-image: url('../../assets/img/banner-bg.png'); */
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.banner .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
}

.banner h1 {
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
}

.banner p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
}

.banner button {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
}

.banner a {
    text-decoration: none;
}

.banner button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
}

.tagline {
    pointer-events: none;
}

.banner button:hover svg {
    margin-left: 25px;
}

.banner img {
    /* animation: updown 3s linear infinite; */
    /* width: 70%; */
    padding-top: 2rem;
}

.a .btn {
    width: max-content;
    display: inline-block;
    /* color: white; */
    color: var(--color-primary);
    padding: 0.75rem 1.2 rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.a .btn :hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.a .btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

.btn-cv {
    color: #756ab5;
}

.fixd {
    height: 100%;
    font-size: 1.7em;
    font-weight: bold;
}

.banner_container {
    width: 86%;
    display: flex;
    margin: 0 auto;
}

.banner_row {
    align-items: center;
    display: grid;
    /* for Image */
    /* grid-template-columns: 1fr 1fr; */

    grid-template-columns: 1fr;
}

.banner_column1 {
    width: 100%;
}

@keyframes updown {
    0% {
        transform: translateY(-20px);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(-20px);
    }
}

.txt-rotate>.wrap {
    border-right: 0.08em solid #666;
}

@media screen and (max-width : 1200px) {
    /* for Image */
    /* .banner_row{
        grid-template-columns: 1fr;
    } */
    /* .banner_img{
        width:80%;
        margin: 0 auto;
    } */

    .banner {
        padding-bottom: 1rem;
    }

    .banner h1 {
        font-size: 55px !important;
    }
}

@media screen and (max-width : 1024px) {
    .Banner-h1>h2 {
        font-size: 1.5rem !important;
    }

    .banner h1 {
        font-size: 55px !important;
    }
}

@media screen and (max-width : 800px) {
    .Banner-h1>h2 {
        font-size: 1rem !important;
    }

    .banner h1 {
        font-size: 40px !important;
    }
}

@media screen and (max-width : 300px) {
    .banner h1 {
        font-size: 30px !important;
    }
}