:root {
    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 95%;

    --color-white: #fff;
    --color-primary: #4db5ff;
    --color-bg: #1f1f38;
    --color-bg-variant: #2e2e65;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-light: rgba(255, 255, 255, 0.6);
}

#project {
    padding-top: 8rem;
    text-align: center;
}

.project_container {
    padding-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 0px;
    width: 100%;
    /* for increase number of projects increase height */
    height: 65rem;
    perspective: 800px;
}

.flip-card-inner {
    /* for inside card content height */
    height: 70%;
    width: 100%;
    position: relative;
    transition: transform 1000ms;
    transform-style: preserve-3d;
}

.flip-card-inner:hover {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    box-shadow: 0 0 5px 2px rgba(50, 50, 50, 0.25);
    position: absolute;
    backface-visibility: hidden;
}

.flip-card-front {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.flip-card-back {
    background-color: var(--color-bg);
    background-image: url('../../assets/img/bg-texture.png');
    /* background-color: #3a3a3a; */
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    border: 1px solid;
    cursor: default;
}

.flip-card-front img {
    width: 300px;
    border-radius: 0.5rem;
}

@media screen and (max-width : 1200px) {
    h3{
        font-size: 1.5rem;
    }

    h4{
        font-size: 0.9rem;
    }

    .project_container {
        gap: 1.5rem;
    }
    .flip-card-front img {
        width: 250px;
    }

    .project_container
    {
        height: 60rem;
    }
}

@media screen and (max-width : 1024px) {
    .project_container>div {
        width: 90%;
        /* padding: 2rem; */
        margin: 0 auto;
    }

    .project_container{
        height: 60rem;
    }
    
    #project{
        padding-top: 5rem;
        padding-bottom: 0rem;
        margin-top: 2rem;
    }
    
    .flip-card-back {
        gap: 3rem;
    }
}

@media screen and (max-width : 1000px) {
    h4{
        font-size: 1.2rem;
    }

    .project_container{
        height: 100rem;
        grid-template-columns: 1fr 1fr;
    }

    .flip-card-front {
        gap: 2rem;
    }
    .flip-card-back {
        gap: 1.3rem;

    }

    .flip-card-front img {
        width: 250px;
    }
}

@media screen and (max-width : 900px) {
    .project_container{
        height: 100rem;
    }
}

@media screen and (max-width : 770px) {
    h4{
        font-size: 1rem;
    }

    .flip-card-front img {
        width: 300px;
    }

    .project_container {
        grid-gap: 1rem;
        padding-top: 0rem;
        grid-template-columns: 1fr;
        height: 105rem;
    }

    .flip-card-inner {
        height: 120%;
    }
    
    #project {
        padding-top: 2rem;
    }
    
    .project_container>div {
        width: 100%;
        padding: 2rem 1rem;
        margin: 0 auto;
    }
}

@media screen and (max-width : 650px) {
    .project_container {
        height: 120rem;
    }
    .flip-card-front img {
        width: 350px;
    }
}

@media screen and (max-width : 500px) {
    .project_container {
        height: 120rem;
    }
}

@media screen and (max-width : 420px) {
    .project_container {
        grid-template-columns: 1fr;
        height: 130rem;
    }

    .flip-card-front img {
        width: 300px;
    }

    .project_container>div {
        width: 100%;
        padding: 2rem 1rem;
        margin: 0 auto;
    }
}

@media screen and (max-width : 380px) {
    .project_container {
        grid-template-columns: 1fr;
        height: 130rem;
    }

    .project_container>div {
        width: 100%;
        padding: 2rem 1rem;
        margin: 0 auto;
    }
}

@media screen and (max-width : 360px) {
    .flip-card-front img {
        width: 230px;
    }
}