:root {
    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 95%;

    --color-white: #fff;
    --color-primary: #4db5ff;
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-light: rgba(255, 255, 255, 0.6);
}

.scroll-nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    backdrop-filter: blur(15px);
    border-radius: 3rem;
}

.scroll-nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

.scroll-nav a:hover {
    background: rgba(0, 0, 0, 0.7);
    /* filter: brightness(0) saturate(100%) invert(1) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%); */
    filter:invert(1) contrast(86%);
}

.scroll-nav .active {
    background: rgba(0, 0, 0, 0.7);
    filter:invert(1) contrast(86%);
}

@media screen and (max-width : 280px) {
    .scroll-nav{
        padding: 0.2rem 0.4rem;
        margin-left: -1rem;
    }
}