:root {
    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 95%;

    --color-white: #fff;
    --color-primary: #4db5ff;
    --color-bg: #1f1f38;
    --color-bg-variant: #2e2e65;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-light: rgba(255, 255, 255, 0.6);
}

#experience {
    padding-top: 0rem;
    text-align: center;
}

.experience_container {
    padding-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.experience_container>div {
    background: var(--color-bg-variant);
    padding: 2.4rem 3.5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
}

.experience_container>div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.experience_card{
    padding: 1rem;
}

.experience_card:hover {
    background: transparent;
    transition: var(--transition);
    border: 1px solid;
    cursor: default;
}

.experience_details {
    display: flex;
}

.experience_details>div {
    margin-left: 0.7rem;
    margin-top: -0.3rem;
}

.experience_icon {
    color: var(--color-primary);
}


@media screen and (max-width : 1024px) {
    .experience_container>div {
        width: 90%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience_content {
        padding: 1rem;
    }

    #experience{
        margin-top: 0;
        padding-bottom: 1rem;
    }
}

@media screen and (max-width : 1200px) {
    h3{
        font-size: 1.5rem;
    }

    h4{
        font-size: 0.9rem;
    }

    #experience {
        padding-top: 5rem;
    }
}

@media screen and (max-width : 1000px) {
    .experience_content {
        grid-template-columns: 1fr;
    }

    h4{
        font-size: 1.2rem;
    }
}

@media screen and (max-width : 800px) {
    h4{
        font-size: 1rem;
    }
}

@media screen and (max-width : 770px) {
    .experience_container {
        gap: 1rem;
        grid-template-columns: 1fr;
    }

    .experience_container>div {
        width: 100%;
        padding: 2rem 1rem;
        margin: 0 auto;
    }

    .experience_content {
        grid-template-columns: 1fr;
    }
}