:root {
    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 95%;

    --color-white: #fff;
    --color-primary: #4db5ff;
    --color-bg: #1f1f38;
    --color-bg-variant: #2e2e65;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-light: rgba(255, 255, 255, 0.6);
}

#contact {
    padding-top: 0rem;
    text-align: center;
}

.contact_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.contact_options {
    padding: 2.4rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact_option {
    background: var(--color-bg-variant);
    border: 1px solid var(--color-bg-variant);
    border-radius: 1.2rem;
    padding: 1.2rem;
}

.contact_option:hover {
    background: transparent;
    border: 1px solid var(--color-white);
    transition: var(--transition);
}

.contact_option>a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1.2rem;
    text-decoration: none;
}

.contact_option>h4,
h5 {
    text-align: center;
    color: var(--color-white);
}

.contact_icon {
    font-size: 2rem;
    margin-bottom: 1rem;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.2rem;
    margin-right: 5%;
}

input,
textarea {
    background: transparent;
    width: 100%;
    border: 1px solid var(--color-white);
    border-radius: 0.3rem;
    padding: 1rem;
    color: var(--color-white);
    resize: none;
}

.btn_submit {
    margin: 0 40%;
    padding: 1rem 0;
    border: 1px solid var(--color-bg-variant);
    border-radius: 1rem;
    background: var(--color-bg-variant);
    color: white;
}

.btn_submit:hover {
    color: white;
    background: transparent;
    text-decoration: none;
    transition: var(--transition);
    border: 1px solid var(--color-white);
}


@media screen and (max-width : 1200px) {
    h3 {
        font-size: 1.5rem;
    }
    h4 {
        font-size: 0.9rem;
    }
}

@media screen and (max-width : 1024px) {
    .contact_options {
        width: 90%;
        padding: 1rem 2rem;
        margin: 0 auto;
    }

    .contact_container {
        padding-top: 0;
    }

    #contact{
        margin-top: 0;
        padding-bottom: 1rem;
    }
}


@media screen and (max-width : 1000px) {
    h4 {
        font-size: 1.2rem;
    }
}

@media screen and (max-width : 800px) {
    h4 {
        font-size: 1rem;
    }

    .btn_submit {
        padding: 0;
    }
}

@media screen and (max-width : 770px) {
    .contact_container {
        gap: 1rem;
        grid-template-columns: 1fr;
    }

    #contact {
        padding-top: 5rem;
    }

    .contact_options {
        width: 100%;
        padding: 1rem 1rem;
        margin: 0 auto;
    }

    form{
        margin:0 10%;
    }
}